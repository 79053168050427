
export function limitsReducer(acc, curr) {
    if (!acc) {
        return {
            minX: curr[0],
            minY: curr[1],
            maxX: curr[0],
            maxY: curr[1]
        };
    }
    return {
        minX: Math.min(curr[0], acc.minX),
        minY: Math.min(curr[1], acc.minY),
        maxX: Math.max(curr[0], acc.maxX),
        maxY: Math.max(curr[1], acc.maxY)
    };
}


export function timeAxisTicks (start, stop, count)  {
    function calcInterval() {
        const intervals = [1000, 5000, 15000, 30000, 60000, 300000, 900000, 1800000, 3600000, 10800000, 21600000, 43200000, 86400000, 172800000, 604800000]
        const target = Math.floor((stop - start) / count)
        let candidate = intervals[0];
        for (const i of intervals) {
            candidate = i
            if (target < candidate) { break; }
        }
        return candidate;
    }

    const interval=calcInterval(), remainder = start % interval 
    const first = remainder === 0 ? start : start - remainder + interval
    const tickValues=[];
    for(let tick = first; tick<=stop ; tick+=interval) {
        tickValues.push(tick);
    }
    return tickValues;
}

/* Below are shamelessly pinched from D3 https://github.com/d3/d3-array/blob/master/src/ticks.js */

const e10 = Math.sqrt(50), e5 = Math.sqrt(10), e2 = Math.sqrt(2);
export function tickIncrement(start, stop, count) {
    var step = (stop - start) / Math.max(0, count),
        power = Math.floor(Math.log(step) / Math.LN10),
        error = step / Math.pow(10, power);
    return power >= 0
        ? (error >= e10 ? 10 : error >= e5 ? 5 : error >= e2 ? 2 : 1) * Math.pow(10, power)
        : -Math.pow(10, -power) / (error >= e10 ? 10 : error >= e5 ? 5 : error >= e2 ? 2 : 1);
}

export function linearAxisTicks(start, stop, count) {
    let i = -1, n, ticks, reverse, step;

    if (start === stop && count > 0) return [start];
    if (stop < start) {
        n = start
        start = stop
        stop = n
        reverse = true
    }
    if ((step = tickIncrement(start, stop, count)) === 0 || !isFinite(step)) return [];

    if (step > 0) {
        start = Math.ceil(start / step);
        stop = Math.floor(stop / step);
        ticks = new Array(n = Math.ceil(stop - start + 1));
        while (++i < n) ticks[i] = (start + i) * step;
    } else {
        step = -step;
        start = Math.floor(start * step);
        stop = Math.ceil(stop * step);
        ticks = new Array(n = Math.ceil(stop - start + 1));
        while (++i < n) ticks[i] = (start + i) / step;
    }

    if (reverse) ticks.reverse();
    return ticks;
}

