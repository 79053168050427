import React from 'react';

export default ({data}) => {
    const toRow = (d, id) => {
        return (
        <tr className={d.type} key={id}>
            <td>{d.start}</td>
            <td>{d.end}</td>
            <td>{d.type}</td>
            <td>{d.grams}</td>
        </tr>
    )}
    return (
        <table>
            <thead>
                <tr>
                    <th>Start</th>
                    <th>End</th>
                    <th>Type</th>
                    <th>Grams</th>
                </tr>
            </thead>
            <tbody>
                {data.map((d, id) => toRow(d, id)).reverse()}
            </tbody>
        </table>
    )
}
