import React, { useContext } from 'react';
import './App.css';
import LineChart from './LineChart';
import EventList from './EventList';
import DataProvider, { DataContext, getSeries, getLatest } from './DataProvider';
import Image from './fcdc.png';

function CatfeederChart() {
    const { state } = useContext(DataContext)
    return (<div className="catChart"><LineChart xLabel='Time' yLabel='Weight (g)' data={getSeries(state, 'median_iot_catfeeder/cf-1')} /></div>)
}

function CatfeederLatest() {

    const { state } = useContext(DataContext)
    const latest = getLatest(state, 'input_iot_catfeeder/cf-1')

    const componentMap = {
        'CONNECTED' : (<><span className="value">{latest.weight} grams</span> <span>at {latest.timestamp}</span></>),
        'LOADING' : (<span>Loading ...</span>),
        'NOT_CONNECTED' : (<span>Not connected</span>)
    }

    return (
        <div className="latest">
            {componentMap[state.status]}
        </div>)
}

function CatfeederEventList() {
    const { state } = useContext(DataContext)
    return <EventList data={getSeries(state, 'state_iot_catfeeder/cf-1')} />
}

function CatLogo() {
    const { state } = useContext(DataContext)
    const classMap =  {
        'CONNECTED': 'logo',
        'NOT_CONNECTED': 'logo nc',
        'LOADING' : 'logo loading'
    }
    return (<img className={classMap[state.status]} alt="cat" src={Image}/>)
}

function App() {
    return (
        <div className="parent">
            <DataProvider>
                <header>
                    <CatLogo />
                    <div className="right">
                        <h1>fed cat / dead cat</h1>
                        <span className="telegram">Telegram updates: <a href="https://t.me/fedcatdeadcat" alt="telegram channel">t.me/fedcatdeadcat</a></span>
                    </div>
                </header>
                <main>
                    <CatfeederLatest />
                    <CatfeederChart />
                    <CatfeederEventList />
                </main>
            </DataProvider>
        </div>
    );
}


export default App;
