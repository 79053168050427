import React, { useEffect, useRef, useReducer } from 'react'

const baseUrl = ''
export const DataContext = React.createContext()

const initialDataState = { series:[], latest:{timestamp:null, value:0}}

/* hacked these two functions around to cope with different series types */
function toDatum(message) {
    if (message.timestamp) {

        const mapX = d => Date.parse(d.timestamp),
            mapY = d => d.weight
        return [mapX(message), mapY(message)]
    } else {
        return message;
    }
}

function isAfter(a, b) {
    if(Array.isArray(a)) {
        return b == null || a[0] > b[0]
    } else {
        return b == null || new Date(a.end) > new Date(b.end)
    }
}

const limit = (size, data) => data.length > size ? data.slice(data.length - size, data.length) : data
const lastElement = arr => (arr && arr.length > 0) ? arr[arr.length -1] : null
const lastElementValue = arr => (arr && arr.length > 0) ? arr[arr.length -1].value : null

function seriesReducer(state={}, action) {
    if(action.type === "SERIES_RECEIVED") {
        const data = limit(5000, action.data.map(d => toDatum(d.value)))
        return { ...state, 
            [action.series]:{
                data:data,
                latest: lastElementValue(action.data)
            }
        }
    }
    if (action.type === "MESSAGE_RECEIVED") {
        const rtnVal = {
            ...state,
            [action.series]:{
                ...state[action.series],
                latest: action.data.value
            }
        }
        const oldSeries = state[action.series] ? state[action.series].data : null
        if(oldSeries) {
            const newPoint = toDatum(action.data.value)
            if(isAfter(newPoint, lastElement(oldSeries))) {
                rtnVal[action.series].data = limit(5000, [  ...oldSeries, newPoint ])
            }
        }
        return rtnVal;
    }
    return state;
}

function statusReducer(data, {type}) {
    const map = {
        "CONNECTION_OPENED": "LOADING",
        "CONNECTION_CLOSED": "NOT_CONNECTED",
        "MESSAGE_RECEIVED": "CONNECTED"
    }
    return map[type] || data;
}

function combinedReducer(state={}, action) {
    const newState = {
        series: seriesReducer(state.series, action),
        status: statusReducer(state.status, action)
    }
    return newState;
}

function fetchData(series, dispatch) {
    return fetch(baseUrl + '/series/' + series)
        .then(res => res.json())
        .then(res => dispatch({ type:"SERIES_RECEIVED", series: series, data: res}));
}

function eventToAction(e) {
    const json = JSON.parse(e.data)
    return {
        type:"MESSAGE_RECEIVED",
        series: json.topic + '/'+ json.key,
        data: json.value
    }
}

export default ({children}) => {
    const [ state, dispatch ] = useReducer(combinedReducer,{ status:"LOADING", ...initialDataState})

    const es = useRef(null);

    useEffect(() => {
        Promise.all([
            fetchData('median_iot_catfeeder/cf-1', dispatch),
            fetchData('state_iot_catfeeder/cf-1', dispatch)
        ]).then(p => {
            es.current = new EventSource(baseUrl + '/events')
            es.current.onopen = () => dispatch({ type:"CONNECTION_OPENED" })
            es.current.onerror = e => { console.log(e); dispatch({ type:"CONNECTION_CLOSED"}) }
        })
        return () => {
            es.current?.close();
            dispatch({type:"CONNECTION_CLOSED"})
        }
    }, [es])

    useEffect(() => {
        if(es.current) {
            es.current.onmessage = e => dispatch(eventToAction(e))
        }
    })

    return (
        <DataContext.Provider value={{state, dispatch}}>
            {children}
        </DataContext.Provider>)
}

export function getSeries(state, series) {
    if (state.series[series]) { 
        return state.series[series].data
    }
    return [];
}

export function getLatest(state, series) {
    if (state.series[series]) { 
        return state.series[series].latest
    }
    return {};
}

