import  React from 'react';

import './LineChart.css'
import {timeAxisTicks, linearAxisTicks, limitsReducer} from './chartHelpers'

const LineChart = props => {

    const fudge = {
        tickLength: 0,
        tickTextOffset: 7,
        xLabelOffset:30,
        yLabelOffset:55
    }

    const timeAxisTickFormat = t => {
      const d = new Date(t)
       const pad = n => ("0" + n).slice(-2);
       return `${d.getDate()}/${d.getMonth()+1} ${pad(d.getHours())}:${pad(d.getMinutes())}`
    }


    const svgX = x => (x - limits.minX) * svgWidth / (limits.maxX - limits.minX)
    const svgY = y => svgHeight - ((y - limits.minY) * svgHeight / (limits.maxY - limits.minY))

    const drawPath = () =><path d={scaledData.reduce((acc, curr) => acc + ` L ${curr[0]} ${curr[1]}`, `M ${scaledData[0][0]} ${scaledData[0][1]}`)  } />

        const XTicks = ({t=10}) => {
            return timeAxisTicks(limits.minX, limits.maxX, t).map((t, i) => {
                const x = svgX(t), y = svgY(limits.minY), yTop = svgY(limits.maxY)
                return (
                    <g key={i} className="tick x">
                        <line x1={x} x2={x} y1={yTop}  y2={y} />
                        <text x={x} y={y + fudge.tickTextOffset}>{timeAxisTickFormat(t)}</text>
                    </g>
                )
            })
        }

    const YTicks = ({t=10}) => {
        return linearAxisTicks(limits.minY, limits.maxY, t).map((t, i) => {
            const x = svgX(limits.minX), xRight = svgX(limits.maxX), y = svgY(t)
            return (
                <g key={i} className="tick y">
                    <line x1={xRight} x2={x - fudge.tickLength} y1={y} y2={y} />
                    <text x={x - fudge.tickLength - fudge.tickTextOffset} y={y}>{t}</text>
                </g>
            )
        })
    }

    const XLabel = () => <text className="label x" x={svgWidth/2} y={svgHeight + fudge.xLabelOffset}>{xLabel}</text>
    const YLabel = () => <text className="label y" x={-fudge.yLabelOffset} y={svgHeight/2} transform={`rotate(-90, ${-fudge.yLabelOffset}, ${svgHeight/2})`}>{yLabel}</text>

        const Axes = () => (
            <g className="linechart axis">
                {/*  <line
                x1={svgX(limits.minX)}
                y1={svgY(limits.minY)}
                x2={svgX(limits.maxX)}
                y2={svgY(limits.minY)}
            />*/}
                <line
                    x1={svgX(limits.minX)}
                    y1={svgY(limits.minY)}
                    x2={svgX(limits.minX)}
                    y2={svgY(limits.maxY)}
                />
                <XTicks/>
                <YTicks/>
                <XLabel/>
                <YLabel/>

            </g>
        )


    /* calculate and render */
    const margin=[5,6,15,9], scalingFactor=40, aspectRatio=[24,10]
    const svgWidth  = scalingFactor * aspectRatio[0]
    const svgHeight = scalingFactor * aspectRatio[1] 

    const { data, xLabel, yLabel } = props;
    const limits = data.reduce(limitsReducer, null);
    const scaledData = data.map(p => [svgX(p[0]), svgY(p[1])])

    return <svg className="linechart" viewBox={`0 0 ${svgWidth * (1 + (0.01 * margin[1]) + (0.01 *margin[3]))} ${svgHeight * (1 + (0.01 * margin[0]) + (0.01 * margin[2]))}`}>
        <g className="chart-area" width={svgWidth} height={svgHeight} transform={`translate(${(margin[3])*svgWidth/100},${margin[0]*svgHeight/100})`}>
            {data.length >0 && <Axes /> }
            {data.length >0 && drawPath() }
        </g>
    </svg>
}

export default LineChart;
